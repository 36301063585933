<template>
  <div class="correlation-edit">
    <Loader v-if="isLoading" />
    <div v-else>
      <h3 style="margin-bottom: 25px">
        {{ $l.get("correlation-edit.header") }}
      </h3>

      <CorrelationForm
        :correlation="data.correlation"
        :onSubmit="onSubmitForm"
        v-if="data && data.correlation"
      />
    </div>
  </div>
</template>

<script>
import SubmitFormInteractor from "@/business/correlation-edit/submit-form";
import InitCorrelationEditScreenInteractor from "@/business/correlation-edit/init-correlation-edit";
import CorrelationEditScreenController from "@/adapters/controllers/screen-correlation-edit";
import Loader from "@/application/components/Loader";
import CorrelationForm from "@/application/components/correlations/CorrelationForm";
// import AutoComplete from "@/application/components/AutoComplete";

export default {
  screen_name: "correlation-edit",
  data() {
    return {
      controller: null,
      interactors: {
        submitForm: null,
        initCorrelationEditScreen: null
      },
      data: null,
      isLoading: false
    };
  },
  components: { Loader, CorrelationForm },
  beforeMount() {
    this.controller = this.$injector.get(CorrelationEditScreenController);

    //{ INTERACTORS
    this.interactors.initCorrelationEditScreen = this.$injector.get(
      InitCorrelationEditScreenInteractor
    );
    this.interactors.submitForm = this.$injector.get(SubmitFormInteractor);
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    const id = this.$route.params.id;
    this.interactors.initCorrelationEditScreen.handle(id);
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onSubmitForm() {
      const correlation = this.data.correlation;
      this.interactors.submitForm.handle(correlation);
    }
  }
};
</script>

<style lang="scss"></style>
