<template>
  <div class="correlation-form">
    <form @submit.prevent="() => {}">
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("dictionary.general-data") }}</h3>
        </div>
        <vs-input
          :label-placeholder="
            $l.get('correlation-form.name-field', 'capitalize')
          "
          :danger="isNameInvalid"
          :danger-text="$l.get('errors.input-required', 'capitalize')"
          v-model="correlation.name"
        />
      </vs-card>
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("dictionary.algorithm") }}</h3>
        </div>
        <vs-select
          v-model="correlation.algorithm"
          class="w-full select-large"
          :label="$l.get('dictionary.algorithm')"
        >
          <vs-select-item
            :value="1"
            :text="$l.get('correlation-form.algorithm-percentage-variance')"
          />
          <vs-select-item
            :value="2"
            :text="$l.get('correlation-form.algorithm-percentage-delta')"
          />
        </vs-select>
        <p style="margin-top: 25px">
          {{ $l.get("correlation-form.algorithm-settings") }} ({{
            correlation.algorithmValue
          }}%)
        </p>
        <vs-slider
          ticks
          step="1"
          :min="0"
          :max="100"
          v-model="correlation.algorithmValue"
        />
      </vs-card>
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("correlation-form.control-group-settings") }}</h3>
        </div>
        <vs-select
          v-model="correlation.controlGroupType"
          class="w-full select-large"
          :label="$l.get('correlation-form.control-group-type')"
        >
          <vs-select-item
            :value="1"
            :text="$l.get('correlation-form.control-group-type-default')"
          />
          <vs-select-item
            :value="2"
            :text="$l.get('correlation-form.control-group-type-split-check')"
          />
        </vs-select>
      </vs-card>
      <!-- <input
        type="submit"
        style="position: absolute; left: -9999px; width: 1px; height: 1px;"
        tabindex="-1"
      /> -->
    </form>
    <div style="margin-top: 25px; margin-bottom: 25px; text-align: right">
      <vs-button color="primary" type="filled" @click="onFormSubmit()">
        {{ $l.get("dictionary.submit-new") }}
      </vs-button>
    </div>
  </div>
</template>

<script>
const RE_RSID = /^([Ii]|[Rr][Ss])\d+$/i;

export default {
  name: "correlation-form",
  props: ["correlation", "onSubmit"],
  data() {
    return {
      isNameInvalid: false
    };
  },
  mounted() {},
  methods: {
    onFormSubmit() {
      this.isNameInvalid = this.correlation.name.length == 0;
      if (this.isNameInvalid) {
        return;
      } else {
        this.onSubmit();
      }
    }
  }
};
</script>

<style lang="scss">
.correlation-form .vs-con-input-label {
  width: 100% !important;
  margin-top: 25px;
}

.correlation-form .criteria-node {
  cursor: pointer;
  padding: 0 2px;
  min-width: 60px;
  text-align: center;
  font-size: 11px;
  height: 30px;
  border: 2px solid #e8e8e3;
  border-radius: 2px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  background: #fff;
}

.correlation-form .criteria-node:active {
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1);
  margin: 1px 0 0 1px;
  border: 2px solid #d3d3cb;
}

.correlation-form .criteria-node .node-name {
  line-height: 30px;
  color: #9b9b9b;
}

.correlation-form .criteria-node:hover .node-name {
  color: #8b8b8b;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.15);
}

// .correlation-form .vs-input {
//   margin: 15px;
// }
</style>
